import { useNavigate, useParams } from 'react-router-dom';
import { Layout } from '../../../components/layout';
import ButtonBack from '../../../components/ui/buttonBack';
import { useEffect, useState } from 'react';
import { useTokenStore } from '../../../store/token';
import { TournamentData } from '../../../types/types';
import { useApi } from '../../../hooks/useApi';
import {
	CurrencyFormatter,
	sentryCapture,
	showWalletAndBalance,
} from '../../../helpers/utils';
import { SentryError } from '../../../types/enums';
import {
	RiDownload2Line,
	RiFileCopyLine,
	RiRestartLine,
} from '@remixicon/react';
import Tags from '../../../components/ui/tags';
import CarouselTournament from '../../../components/Carousel/Tournament';
import TabsInTournament from '../../../components/TabsInTournament';
import { ResultsOptions } from '../Tournaments/ResultsOptions';
import TabsButtonLeague from '../../../components/TabsButtonLeague';
import { TableLeague } from '../../../components/TableLeague';
import { ReplyOptions } from '../Tournaments/ReplyOptions';
import { toast } from 'sonner';
import Toast from '../../../components/ui/toast';
import { Games } from '../../../components/Tournaments/Games';
import { ProvablyFairIcon } from '../../../components/Icons/ProvablyFair';
import { copyToClipboard } from '../../../helpers/utilsUi';
import { FutProIcon } from '../../../components/Icons/FutProIcon';
import { Capacitor } from '@capacitor/core';

export const EnrollById = () => {
	const { id } = useParams();
	const api = useApi();
	const navigate = useNavigate();
	const token = useTokenStore((state: any) => state.token);
	const [provabilyFair, setProvabilyFair] = useState(false);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<TournamentData>();
	const prizeAmtTournament =
		data &&
		data.leaguesTournament.reduce((acc, league) => {
			return acc + parseFloat(league.prizeAmt);
		}, 0);

	const fetchEnroll = async () => {
		if (!id) return;
		try {
			setLoading(true);
			const res = await api.getEnroll(id, token);

			if (!res.success) {
				toast(<Toast variant="error">{res.message}</Toast>);
				return navigate('/tournaments');
			}
			setData(res.data);
		} catch (error: any) {
			sentryCapture(error, 'getEnroll', SentryError.Error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (location.pathname) {
			fetchEnroll();
		}
	}, [location, id]);

	const Rules = ({ rule }: any) => {
		return (
			<div
				className="w-full h-full z-0 bg-[#f4f4f4] p-2 rounded-lg"
				dangerouslySetInnerHTML={{
					__html:
						Capacitor.getPlatform() === 'ios'
							? rule.contentIOS
							: Capacitor.getPlatform() === 'android'
							? rule.contentAndroid
							: rule.content,
				}}
			/>
		);
	};

	const thead = [
		{
			title: 'Inscrição',
		},
		{
			title: 'Liga',
		},
		{
			title: 'Posição',
		},
		{
			title: 'Pontos',
		},
		{
			title: 'Prêmio',
		},
	];

	const Participants = ({ participants, leagues }: any) => {
		const leagueArray = leagues.map((item: any, index: number) => ({
			title: item.league.title,
			renderPage: () => (
				<TableLeague
					key={index}
					thead={thead}
					tbody={participants.filter(
						(league: any) => league.league === item.league.title
					)}
					tournamentStatus={data?.tournamentStatus}
				/>
			),
		}));

		const render =
			leagues.length > 1
				? [
						{
							title: 'Todos',
							renderPage: () => (
								<TableLeague
									thead={thead}
									tbody={participants}
									tournamentStatus={data?.tournamentStatus}
								/>
							),
						},
						...leagueArray,
				  ]
				: [...leagueArray];

		return (
			<div className="flex flex-col gap-2">
				<TabsButtonLeague
					initialPage={leagues.length > 1 ? 'Todos' : render[0].title}
					render={render}
				/>
			</div>
		);
	};

	return (
		<Layout>
			<div className="px-3 lg:max-w-screen-xl lg:mx-auto">
				{loading ? (
					<div className="flex items-center justify-center">
						<RiRestartLine className="mr-2 h-4 w-4 animate-spin" />
					</div>
				) : (
					<>
						<div className="flex items-center flex-row justify-between py-6 lg:max-w-screen-xl lg:mx-auto lg:pt-4">
							<ButtonBack classNameIcon="" />
							<p className="text-xl font-semibold text-custom-gray1 font-bahnschrift">
								{data?.owner
									? 'Minha inscrição'
									: data?.enroll?.nick}
							</p>
							<div></div>
						</div>

						<div className="flex flex-col gap-2 mb-4">
							{data && (
								<div className="flex flex-col gap-2 bg-custom-white1 p-2.5 rounded-lg">
									<div className="w-full flex flex-col gap-2 rounded-lg py-3">
										<Tags status={data.tournamentStatus} />
										<div className="w-full h-60">
											{data?.content ? (
												data.content?.image ? (
													<img
														className="lg:h-80 w-full h-full object-cover z-0"
														alt={data.content.title}
														src={
															data?.content.image
														}
													/>
												) : (
													<div
														className="w-full h-full z-0"
														dangerouslySetInnerHTML={{
															__html: data
																?.content.html,
														}}
													/>
												)
											) : (
												<div className="flex flex-col items-start justify-start gap-6 p-4 w-full h-full bg-[#d9d9d9] rounded-lg">
													<div className="flex flex-col items-start justify-start gap-0.5 w-full h-28">
														<div className="text-primary font-bold text-xs font-bahnschrift break-words w-full">
															<strong>
																Torneio
															</strong>
														</div>
														<div className="flex flex-col items-start justify-start gap-2 w-full h-full">
															<div className="flex flex-col items-start justify-start gap-1 w-full h-10">
																<div className="text-black font-bold text-2xl font-bahnschrift break-words w-full">
																	<strong>
																		{
																			data?.title
																		}
																	</strong>
																</div>
																<div className="text-black text-base font-light font-bahnschrift break-words w-full flex items-center gap-1">
																	Participe e
																	concorra a{' '}
																	{showWalletAndBalance() ? (
																		'R$ '
																	) : (
																		<FutProIcon
																			style="w-4 h-4"
																			color="#000000"
																		/>
																	)}
																	{CurrencyFormatter(
																		Number(
																			prizeAmtTournament
																		)
																	)}{' '}
																	em prêmio
																</div>
																<div className="text-black text-xs font-light font-bahnschrift leading-[1.2] break-words w-full">
																	Mostre que
																	sabe tudo de
																	futebol
																	respondendo{' '}
																	{
																		data?.optionSelection
																	}{' '}
																	perguntas do
																	quiz deste
																	torneio.
																</div>
																<div className="text-black text-xs font-light font-bahnschrift leading-[1.2] break-words w-full">
																	Entre as{' '}
																	{
																		data?.optionTotal
																	}{' '}
																	perguntas
																	disponíveis,
																	responda{' '}
																	{
																		data?.optionSelection
																	}{' '}
																	mostrando a
																	sua
																	habilidade
																	para
																	conquistar o
																	máximo de
																	pontos e
																	garantir o
																	seu prêmio.
																</div>
															</div>
														</div>
													</div>
												</div>
											)}
											<div
												onClick={(e) => {
													e.stopPropagation();
													setProvabilyFair(
														!provabilyFair
													);
												}}
												className="h-8 rounded-[68px] justify-center items-center float-right inline-flex relative bottom-9 right-3 cursor-pointer gap-1 font-bahnschrift text-xs text-[#a2a5ab]"
											>
												<ProvablyFairIcon />
												Provably Fair
											</div>
										</div>
									</div>
									{provabilyFair && (
										<div className="px-4 py-2 bg-gray-100 rounded-lg flex-col justify-center items-start gap-2.5 inline-flex">
											<div className="self-stretch justify-start items-center gap-2 inline-flex">
												<ProvablyFairIcon />
												<div className="text-[#a2a5ab] text-[11px] font-semibold font-bahnschrift">
													Provably Fair
												</div>
											</div>

											<div className="self-stretch justify-start items-center gap-2 inline-flex">
												<div className="w-full">
													<span className="text-neutral-800 text-[13px] font-normal font-bahnschrift leading-snug">
														Chave pública gerada
														pelo servidor:
														<br />
													</span>
													<div className="flex flex-row gap-1 items-center justify-center">
														<input
															type="text"
															className="text-neutral-800 text-[13px] rounded font-semibold font-bahnschrift w-full p-1 border border-b-neutral-800"
															value={
																data.pfPublicKey
															}
															disabled={true}
														/>
														<div
															onClick={() => {
																copyToClipboard(
																	data.pfPublicKey
																);
															}}
														>
															<RiFileCopyLine className="text-neutral-800" />
														</div>
													</div>
												</div>
											</div>
											<div className="self-stretch justify-start items-center gap-2 inline-flex">
												<div className="w-full">
													<span className="text-neutral-800 text-[13px] font-normal font-bahnschrift leading-snug">
														Hash das respostas da
														matricula:
														<br />
													</span>
													<div className="flex flex-row gap-1 items-center justify-center">
														<input
															type="text"
															className="text-neutral-800 text-[13px] rounded font-semibold font-bahnschrift w-full p-1 border border-b-neutral-800"
															value={
																data.pfClosedHash
															}
															disabled={true}
														/>
														<div
															onClick={() => {
																copyToClipboard(
																	data.pfClosedHash
																);
															}}
														>
															<RiFileCopyLine className="text-neutral-800" />
														</div>
													</div>
												</div>
											</div>
											{data.pfPrivateKey && (
												<div className="self-stretch justify-start items-center gap-2 inline-flex">
													<div className="w-full">
														<span className="text-neutral-800 text-[13px] font-normal font-bahnschrift leading-snug">
															Chave privada gerada
															no servidor:
															<br />
														</span>
														<div className="flex flex-row gap-1 items-center justify-center">
															<input
																type="text"
																className="text-neutral-800 text-[13px] rounded font-semibold font-bahnschrift w-full p-1 border border-b-neutral-800"
																value={
																	data.pfPrivateKey
																}
																disabled={true}
															/>
															<div
																onClick={() => {
																	copyToClipboard(
																		data.pfPrivateKey
																	);
																}}
															>
																<RiFileCopyLine className="text-neutral-800" />
															</div>
														</div>
													</div>
												</div>
											)}
											{data?.pfFileUrl && (
												<div
													onClick={() => {
														const link =
															document.createElement(
																'a'
															);
														link.href =
															data.pfFileUrl;
														link.download =
															'provablyFair.txt';
														link.target = '_blank';
														link.click();
													}}
													className="w-full h-8 flex justify-end"
												>
													<RiDownload2Line className="w-6 h-6 text-black" />
												</div>
											)}
										</div>
									)}
									<CarouselTournament
										data={data?.leaguesTournament
											.filter(
												(league) => league.isSelected
											)
											.sort((a: any, b: any) => {
												return a.sequence - b.sequence;
											})}
										tournamentId={data.id}
										tournamentStatus={data.tournamentStatus}
										enrollPage={true}
									/>

									<TabsInTournament
										initialPage=""
										render={[
											{
												title: 'Jogos',
												renderPage: () => (
													<Games
														eventsTournament={
															data.eventsTournament
														}
													/>
												),
											},
											{
												title: 'Resultados',
												renderPage: () => (
													<ResultsOptions
														data={data}
													/>
												),
											},
											{
												title: 'Inscrições',
												renderPage: () => (
													<Participants
														participants={
															data.participants
														}
														leagues={data?.leaguesTournament.sort(
															(
																a: any,
																b: any
															) => {
																return (
																	a.sequence -
																	b.sequence
																);
															}
														)}
													/>
												),
											},
											{
												title: 'Regras',
												renderPage: () => (
													<Rules rule={data.rule} />
												),
											},
										]}
									/>

									<ReplyOptions data={data} />
								</div>
							)}
						</div>
					</>
				)}
			</div>
		</Layout>
	);
};
