import { useParams } from 'react-router-dom';
import { Layout } from '../../../components/layout';
import { useTokenGetStreamStore, useTokenStore } from '../../../store/token';
import { useCallback, useEffect, useState } from 'react';
import {
	areMandatoryQuestionsAnswered,
	CurrencyFormatter,
	sentryCapture,
	showWalletAndBalance,
} from '../../../helpers/utils';
import { SentryError } from '../../../types/enums';
import { useApi } from '../../../hooks/useApi';
import { TournamentData } from '../../../types/types';
import { RiRestartLine } from '@remixicon/react';
import TabsInLine from '../../../components/TabsInLine';
import Carousel from '../../../components/Carousel';
import { useEnrollContext } from '../../../contexts/Enroll/EnrollContext';
import ButtonBack from '../../../components/ui/buttonBack';
import { Options } from './Option';
import { toast } from 'sonner';
import Toast from '../../../components/ui/toast';
import { useModalBalanceAlertStore } from '../../../store/modalBalanceAlert';
import { TableLeague } from '../../../components/TableLeague';
import TabsButtonLeague from '../../../components/TabsButtonLeague';
import { useAuthContext } from '../../../contexts/Auth/AuthContext';
import { LiveChat } from '../../../components/LiveChat';
import { ResultsOptions } from '../Tournaments/ResultsOptions';
import { Games } from '../../../components/Tournaments/Games';
import { FutProIcon } from '../../../components/Icons/FutProIcon';
import { Capacitor } from '@capacitor/core';

export const TournamentById = () => {
	const { id } = useParams();
	const api = useApi();
	const { user } = useAuthContext();
	const {
		enroll,
		enrollOptions,
		optionTournament,
		optionSelection,
		initialSelectEnroll,
		enrollOption,
		SetOptionTournament,
		SetOptionSelection,
		SetLeague,
		SetTournament,
		openModalEnroll,
		depositReturnEnroll,
	} = useEnrollContext();
	const {
		urlAction,
		leagues,
		optionsSelect: optionSelected,
		clearEnrollsInfoModal,
	} = useModalBalanceAlertStore();
	const tokenGetStream = useTokenGetStreamStore(
		(state) => state.tokenGetStream
	);
	const token = useTokenStore((state: any) => state.token);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<TournamentData | null>(null);
	const [participants, setParticipants] = useState<any>([]);
	const [optionsSelect, setOptionsSelect] = useState<any>([]);
	const [countSelect, setCountSelect] = useState(0);
	const prizeAmtTournament =
		data &&
		data.leaguesTournament.reduce((acc, league) => {
			return acc + parseFloat(league.prizeAmt);
		}, 0);
	useEffect(() => {
		fetchTournament();
	}, []);

	useEffect(() => {
		enrollOption(optionsSelect);
		setCountSelect(optionsSelect.length);
	}, [optionsSelect]);

	useEffect(() => {
		if (!data) return;
		if (
			!areMandatoryQuestionsAnswered(optionTournament, enrollOptions) &&
			countSelect === optionSelection
		) {
			const handler = setTimeout(() => {
				toast(
					<Toast variant="error">
						Responda as perguntas obrigatórias.
					</Toast>
				);
			}, 1000);
			return () => clearTimeout(handler);
		} else {
			if (enroll.length > 0 && countSelect === optionSelection) {
				if (!user) {
					return;
				}
				setTimeout(() => {
					openModalEnroll();
				}, 2000);
			}
		}
	}, [enroll, enrollOptions, user]);

	const fetchTournament = async () => {
		if (!id) return;
		try {
			setLoading(true);
			const res = await api.getTournament(id, token);

			if (!res?.success) {
				throw new Error(res.message);
			}
			setData(res.data);
			SetTournament({
				tournamentId: id,
				tournamentName: res.data.title,
				tournamentStatus: res.data.tournamentStatus,
				sharedUrl:
					Capacitor.getPlatform() === 'ios'
						? res.data.sharedUrlIOS
						: Capacitor.getPlatform() === 'android'
						? res.data.sharedUrlAndroid
						: res.data.sharedUrl,
			});
			setParticipants(res.data.participants || []);
			SetLeague(res.data.leaguesTournament);
			SetOptionTournament(res.data.optionsTournament);
			SetOptionSelection(parseInt(res.data.optionSelection));

			if (urlAction) {
				setOptionsSelect(optionSelected);
				enrollOption(optionSelected);
				depositReturnEnroll(leagues);
				clearEnrollsInfoModal();
				openModalEnroll();
			} else {
				initialSelectEnroll({
					leagueId: res.data.leaguesTournament[0].league.id,
					leagueName: res.data.leaguesTournament[0].league.title,
					entryAmt: res.data.leaguesTournament[0].entryAmt,
					prizeAmt: res.data.leaguesTournament[0].prizeAmt,
				});
			}
		} catch (error: any) {
			sentryCapture(error, 'getTournament', SentryError.Error);
		} finally {
			setLoading(false);
		}
	};

	const Rules = () => {
		if (!data) return null;
		return (
			<div
				className="w-full h-full z-0 bg-[#f4f4f4] p-2 rounded-lg"
				dangerouslySetInnerHTML={{
					__html:
						Capacitor.getPlatform() === 'ios'
							? data.rule.contentIOS
							: Capacitor.getPlatform() === 'android'
							? data.rule.contentAndroid
							: data.rule.content,
				}}
			/>
		);
	};

	const thead = [
		{
			title: 'Inscrição',
		},
		{
			title: 'Liga',
		},
		{
			title: 'Posição',
		},
		{
			title: 'Pontos',
		},
		{
			title: 'Prêmio',
		},
	];

	const Participants = ({ participants, leagues }: any) => {
		const leagueArray = leagues?.map((item: any, index: number) => ({
			title: item.league.title,
			renderPage: () => (
				<TableLeague
					key={index}
					thead={thead}
					tbody={participants
						.filter(
							(league: any) => league.league === item.league.title
						)
						.sort((a: any, b: any) => {
							return a.classification - b.classification;
						})}
					tournamentStatus={data?.tournamentStatus}
				/>
			),
		}));

		const render =
			leagues.length > 1
				? [
						{
							title: 'Todos',
							renderPage: () => (
								<TableLeague
									thead={thead}
									tbody={participants.sort(
										(a: any, b: any) => {
											return (
												a.classification -
												b.classification
											);
										}
									)}
									tournamentStatus={data?.tournamentStatus}
								/>
							),
						},
						...leagueArray,
				  ]
				: [...leagueArray];

		return (
			<div className="flex flex-col gap-2">
				<TabsButtonLeague
					initialPage={leagues.length > 1 ? 'Todos' : render[0].title}
					render={render}
				/>
			</div>
		);
	};

	const handleSelectOption = useCallback((newOption: any) => {
		setOptionsSelect((prevOptions: any) => {
			const existingOptionIndex = prevOptions.findIndex(
				(option: any) => option.optionId === newOption.optionId
			);
			if (existingOptionIndex !== -1) {
				if (newOption.delete) {
					return prevOptions.filter(
						(option: any) => option.optionId !== newOption.optionId
					);
				} else {
					const updatedOptions = [...prevOptions];
					updatedOptions[existingOptionIndex] = newOption;
					return updatedOptions;
				}
			} else {
				return [...prevOptions, newOption];
			}
		});
	}, []);

	return (
		<Layout>
			<div className="px-3 lg:max-w-screen-xl lg:mx-auto">
				<div className="items-center flex-row flex justify-between py-6 lg:max-w-screen-xl lg:mx-auto lg:pt-4">
					<ButtonBack classNameIcon="" />
					<p className="text-xl font-semibold text-custom-gray1 font-bahnschrift"></p>
					<div></div>
				</div>
				{loading ? (
					<div className="flex items-center justify-center">
						<RiRestartLine className="mr-2 h-4 w-4 animate-spin" />
					</div>
				) : (
					<div className="flex flex-col gap-2">
						<div className="w-full h-60">
							{data?.content ? (
								data?.content.image ? (
									<img
										className="lg:h-80 w-full h-full object-cover z-0"
										alt={data.content.title}
										src={data.content.image}
									/>
								) : (
									<div
										className="w-full h-full z-0"
										dangerouslySetInnerHTML={{
											__html: data?.content.html,
										}}
									/>
								)
							) : (
								<div className="flex flex-col items-start justify-start gap-6 p-4 w-full h-full bg-custom-gray rounded-lg">
									<div className="flex flex-col items-start justify-start gap-0.5 w-full h-28">
										<div className="text-primary font-bold text-xs font-bahnschrift break-words w-full">
											<strong>Torneio</strong>
										</div>
										<div className="flex flex-col items-start justify-start gap-2 w-full h-full">
											<div className="flex flex-col items-start justify-start gap-1 w-full h-10">
												<div className="text-black font-bold text-2xl font-bahnschrift break-words w-full">
													<strong>
														{data?.title}
													</strong>
												</div>
												<div className="text-black text-base font-light font-bahnschrift break-words w-full flex items-center gap-1">
													Participe e concorra a{' '}
													{showWalletAndBalance() ? (
														'R$ '
													) : (
														<FutProIcon
															style="w-4 h-4"
															color="#000000"
														/>
													)}
													{CurrencyFormatter(
														Number(
															prizeAmtTournament
														)
													)}{' '}
													em prêmio
												</div>
												<div className="text-black text-xs font-light font-bahnschrift leading-[1.2] break-words w-full">
													Mostre que sabe tudo de
													futebol respondendo{' '}
													{data?.optionSelection}{' '}
													perguntas do quiz deste
													torneio.
												</div>
												<div className="text-black text-xs font-light font-bahnschrift leading-[1.2] break-words w-full">
													Entre as {data?.optionTotal}{' '}
													perguntas disponíveis,
													responda{' '}
													{data?.optionSelection}{' '}
													mostrando a sua habilidade
													para conquistar o máximo de
													pontos e garantir o seu
													prêmio.
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
						<Carousel
							data={data?.leaguesTournament.sort(
								(a: any, b: any) => {
									return a.sequence - b.sequence;
								}
							)}
							tournamentStatus={data?.tournamentStatus}
						/>
						{id && (
							<LiveChat
								id={id}
								tournamentId={id}
								token={tokenGetStream}
							/>
						)}
						{data && (
							<TabsInLine
								initialPage={
									data.tournamentStatus === 0
										? 'Perguntas'
										: 'Resultados'
								}
								render={[
									{
										title: 'Jogos',
										renderPage: () => (
											<Games
												eventsTournament={
													data.eventsTournament
												}
											/>
										),
									},
									{
										title:
											data.tournamentStatus === 0
												? 'Perguntas'
												: 'Resultados',
										renderPage: () =>
											data.tournamentStatus === 0 ? (
												<Options
													data={data}
													countSelect={countSelect}
													optionsSelect={
														optionsSelect
													}
													handleSelectOption={
														handleSelectOption
													}
												/>
											) : (
												<ResultsOptions data={data} />
											),
									},
									{
										title: 'Inscrições',
										renderPage: () => (
											<Participants
												participants={participants}
												leagues={data?.leaguesTournament.sort(
													(a: any, b: any) => {
														return (
															a.sequence -
															b.sequence
														);
													}
												)}
												tournamentStatus={
													data?.tournamentStatus
												}
											/>
										),
									},
									{
										title: 'Regras',
										renderPage: () => <Rules />,
									},
								]}
							/>
						)}
					</div>
				)}
			</div>
		</Layout>
	);
};
